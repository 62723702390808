/*------------------------------------*\
    # component.input
\*------------------------------------*/

.input {
    /**
     * Input base
     */
    padding: 0;
    margin: 0;
    border-radius: $global-radial;

    display: inline-flex;
    width: 100%;

    cursor: pointer;
    white-space: nowrap;

    text-decoration: none;
    font-family: $ff-sans;

    background: none;
    background: white;
    border: 1px solid color($color-dark a(0.15));
    color: $color-dark;

    transition: all 0.2s ease-out;

    &::placeholder {
        color: color($color-text a(0.3));
    }

    &:hover {
        background: color($color-primary a(0.05));
        border-color: $color-primary;
    }

    &:focus {
        border-color: $color-primary;
        outline: 0;

        &::placeholder {
            color: $color-primary;
        }
    }

    &[disabled] {
        background: color($color-dark a(0.06));
        cursor: not-allowed;
    }
}

/* Input sizes */
.input--sml {
    @mixin type-scale $type-tiny, 4;
    height: calc($unit * 7);
    line-height: calc($unit * 6);
    padding: 0 calc($unit * 3);
}

.input--med {
    @mixin type-scale $type-small, 4;
    height: calc($unit * 10);
    line-height: calc($unit * 10);
    padding: 0 calc($unit * 4);
}

/* Input types */
.input--text {
}

.input--number {
    padding-right: 0;
}

.input--select {
    @mixin type-scale $type-small, 4;
    height: calc($unit * 7);
    line-height: calc($unit * 6);
}

.input--select--multiple {
    min-height: 160px;
}

.input--textarea {
    @mixin type-scale $type-small, 5;
    width: 100%;
    resize: vertical;
    padding: calc($unit * 4);
    white-space: pre-wrap;
    overflow-wrap: normal;
    outline: none;

    border: 1px solid color($color-dark a(0.08));
    background: white;
    border-radius: $global-radial;

    transition: all 0.2s ease-out;

    &:hover,
    &:focus {
        background: color($color-primary a(0.05));
        border-color: $color-primary;
    }
}

.input--check,
.input--radio {
    position: absolute;
    left: -99999px;
    opacity: 0;

    & + label {
        padding-left: calc($unit * 2);
        @mixin type-scale $type-small, 4;
        color: color($color-text a(0.7));

        &:before {
            content: '';
            display: block;
            float: left;
            width: calc($unit * 3.5);
            height: calc($unit * 3.5);
            margin-top: calc($unit * 2);

            transition: all 0.2s ease-in-out;

            border: 1px solid color($color-dark a(0.3));
            border-radius: $global-radial;
        }
    }

    &:hover,
    &:focus {
        & + label {
            &:before {
                border-color: $color-primary;
                background: color($color-primary a(0.1));
            }
        }
    }

    &:checked {
        & + label {
            &:before {
                background: $color-primary;
                border-color: transparent;
            }
        }
    }
}

.input--radio {
    & + label {
        &:before {
            border-radius: 50%;
        }
    }
}

.input--check--disabled {
    &:hover,
    &:focus {
        & + label {
            &:before {
                border: 1px solid color($color-dark a(0.3));
                background: transparent;
            }
        }
    }

    &:checked {
        & + label {
            &:before {
                background: $color-primary;
                border-color: transparent;
            }
        }
    }
}

.input-group {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.input__search {
    position: relative;
}

.input__search-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}
