/*------------------------------------*\
    # utils.grid
\*------------------------------------*/

.g-split {
    lost-flex-container: row;
}

.g-split__item {
    lost-column: 1/2;
}

.g-three {
    lost-flex-container: row;
}

.g-three__item {
    lost-column: 1/3;
}

.g-inline {
    display: flex;
}

.g-inline__item {
    padding-right: calc($unit * 6);
}

.g-spacebetween {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.g-blocks {
    lost-flex-container: row;
}

.g-blocks__item {
    lost-column: 1/2;
    margin-bottom: lost-vars('gutter');
}
