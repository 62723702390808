/*------------------------------------*\
    # utils.group
\*------------------------------------*/

.u-group {
    &:after {
        content: '';
        display: block;
        clear: both;
    }
}
