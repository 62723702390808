/*------------------------------------*\
    # utils.type
\*------------------------------------*/

/* Alignment */
.t-left {
    text-align: left;
}

.t-center {
    text-align: center;
}

.t-right {
    text-align: right;
}

/* Case */
.t-upper {
    text-transform: uppercase;
}

/* FF */
.t-serif {
    font-family: $ff-serif;
}

/* Sizes */
.t-alpha {
    @mixin type-scale $type-xxlarge, 12;
}

.t-beta {
    @mixin type-scale $type-xlarge, 10;
}

.t-gamma {
    @mixin type-scale $type-large, 9;
}

.t-delta {
    @mixin type-scale $type-medium, 7;
}

.t-epsilon {
    @mixin type-scale $type-base, 6;
}

.t-zeta {
    @mixin type-scale $type-small, 5;
}

.t-eta {
    @mixin type-scale $type-tiny, 3;
}


/* Colors */
.t-primary {
    color: $color-primary;
}

.t-secondary {
    color: $color-secondary;
}

.t-tertiary {
    color: $color-tertiary;
}

.t-complementary {
    color: $brand-complementary;
}

.t-negative {
    color: white;
}

/* Shorten long links */
.t-shorten {
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    hyphens: auto;
}

.t-wrap {
    overflow-wrap: break-word;
    word-wrap: break-word;
}

/* Mandatory filed asterisk*/
.t-mandatory {
    &::after {
        color: black;
        content: ' *';
        display: inline;
    }
}
