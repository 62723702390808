/*------------------------------------*\
    # component.label
\*------------------------------------*/

.label {
    display: inline-flex;
    align-items: center;

    padding: 0 calc($unit * 3);

    @mixin type-scale $type-tiny, 4;
    height: calc($unit * 7);

    border-radius: $global-rounded;
    color: color($color-text a(0.8));
}

.label--primary {
    background: color($color-primary a(0.1));
    border: 1px solid color($color-primary a(0.2));
}

.label--success {
    background: color($color-success a(0.1));
    border: 1px solid color($color-success a(0.2));
}

.label--note {
    background: color($color-note a(0.1));
    border: 1px solid color($color-note a(0.2));
}

.label--warning {
    background: color($color-warning a(0.1));
    border: 1px solid color($color-warning a(0.2));
}

/**
 * Labels with icons
 */

.label--hasIcon span + svg {
    margin-left: calc($unit * 1);
}

.label--hasIcon svg + span {
    margin-left: calc($unit * 1);
}
