/*------------------------------------*\
    # block.nav
\*------------------------------------*/

.nav {
    position: relative;
}

.nav__list {
}

.nav__list__item {
    margin-bottom: calc($unit * 2);
    outline: none;
    user-select: none;
}

.nav__link {
    position: relative;
    display: block;
    padding: calc($unit * 3) 0 calc($unit * 3) calc($unit * 4);

    @mixin type-scale $type-small, 4;
    color: color(white a(0.7));

    transition: all 0.2s ease-out;
    border-radius: $global-radial;

    &:hover,
    &:focus {
        background: $color-bg;
        color: $color-primary;
    }

    &[aria-current] {
        color: $color-primary;
        background: $color-bg;
        box-shadow: 0 0 10px color($color-dark a(0.1));
    }
}

.nav__link--dropdown {
    cursor: pointer;
    outline: none;

    &:after {
        content: '';
        display: block;
        position: absolute;
        top: calc($unit * 4);
        right: calc($unit * 4);
        width: 6px;
        height: 6px;
        border-top: 1px solid color(white a(0.5));
        border-right: 1px solid color(white a(0.5));

        transform: rotate(135deg);
    }

    &:hover,
    &:focus,
    &.nav__button--active {
        &:after {
            border-top-color: $color-primary;
            border-right-color: $color-primary;
        }
    }
}

.nav__link__child {
    margin-left: calc($unit * 4);

    &.active {
        background: color(white a(0.1));
        color: white;
    }
}

.nav__button--active {
    color: $color-primary;
    background: $color-bg;
    box-shadow: 0 0 10px color($color-dark a(0.1));
}
