/*------------------------------------*\
    # utils.float
\*------------------------------------*/

.u-float {
}

.u-float--left {
    float: left;
}

.u-float--right {
    float: right;
}
