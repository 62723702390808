.locations__filters {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    & > div {
        width: 20%;
        margin-right: calc($unit * 4);
    }
}
