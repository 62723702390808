/*------------------------------------*\
    # block.sidebar
\*------------------------------------*/

.sidebar {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-content: space-between;
}

.sidebar__branding {
    padding: calc($unit * 5);
}

.sidebar__branding img {
    max-width: 200px;
}

.sidebar__nav {
    padding: 0 calc($unit * 6) 0 calc($unit * 6);
}

.sidebar__logout {
    width: 100%;
    padding: calc($unit * 5) calc($unit * 10);
    border-top: 1px solid color(white a(0.2));

    @mixin type-scale $type-tiny, 3;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    color: color(white a(0.6));

    &:hover,
    &:focus {
        color: color(white a(0.8));
    }
}

.sidebar__version {
    @mixin type-scale $type-tiny, 3;
    background: color(white a(0.1));
    padding: calc($unit * 3);
    margin: calc($unit * 6);
    border-radius: $global-radial;
    color: color(white a(0.5));
}
