/*------------------------------------*\
    # component.btn
\*------------------------------------*/

.btn {
    /**
     * Button base
     */
    display: inline-flex;
    align-items: center;
    position: relative;

    touch-action: manipulation;
    user-select: none;

    /**
     * Remove border radius on iOS buttons
     */

    /* stylelint-disable property-no-vendor-prefix */
    -webkit-border-radius: 0;
    /* stylelint-enable */

    cursor: pointer;
    white-space: nowrap;
    text-decoration: none;
    text-align: center;
    border: 0;
    border-radius: $global-radial;
    background: transparent;

    transition: all 0.15s ease-out;

    &:focus {
        outline: 0;
    }

    /**
     * Disabled
     */

    &.is-disabled,
    &:disabled {
        cursor: not-allowed;
        opacity: 0.6;
        background-color: transparent;
    }

    &.is-disabled {
        pointer-events: none;
    }
}

/**
 * Button styles
 */

.btn--primary {
    background: $color-primary;
    color: white;
    box-shadow: 0 2px 5px color($color-dark a(0.2));

    &:hover,
    &:focus {
        color: white;
        background: color($color-primary a(0.7));
    }

    &:disabled,
    &.is-disabled {
        background: $color-primary;
    }
}

.btn--secondary {
    background: $color-secondary;
    color: white;

    &:hover,
    &:focus {
        color: white;
        background: color($color-secondary a(0.7));
    }

    &:disabled,
    &.is-disabled {
        background: $color-secondary;
    }
}

.btn--danger {
    background: $color-warning;
    color: white;
    box-shadow: 0 2px 5px color($color-dark a(0.2));

    &:hover,
    &:focus {
        color: white;
        background: color($color-warning a(0.7));
    }

    &:disabled,
    &.is-disabled {
        background: $color-warning;
    }
}

.btn--dark {
    background: $color-dark;
    color: white;
    box-shadow: 0 2px 5px color($color-dark a(0.2));

    &:hover,
    &:focus {
        color: white;
        background: color($color-dark a(0.7));
    }

    &:disabled,
    &.is-disabled {
        background: $color-dark;
    }
}

.btn--outline {
    background: transparent;
    color: $color-text;
    box-shadow: 0 0 0 1px color($color-dark a(0.2)) inset;

    &:hover,
    &:focus {
        color: $color-text;
        box-shadow: 0 0 0 1px color($color-dark a(0.8)) inset;
    }

    &:disabled,
    &.is-disabled {
        background: transparent;
    }
}

.btn--ghost {
    background: transparent;
    color: color($color-dark a(0.6));
    box-shadow: 0 0 0 1px color($color-dark a(0)) inset;

    &:hover,
    &:focus {
        color: color($color-dark a(0.8));
        box-shadow: 0 0 0 1px color($color-dark a(0.3)) inset;
    }

    &:disabled,
    &.is-disabled {
        background: transparent;
    }
}

/**
 * Spacer - a placeholder for text between
 * two or more buttons
 */

.btn-spacer {
    padding: 0 calc($unit);
    @mixin type-scale $type-small, 4;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    color: color(white blackness(+50%));
}

/**
 * Button sizes
 */

.btn--sml {
    @mixin type-scale $type-tiny, 4;
    height: calc($unit * 7);
    padding: 0 calc($unit * 3);
}

.btn--med {
    @mixin type-scale $type-small, 4;
    height: calc($unit * 9);
    padding: 0 calc($unit * 6);
}

.btn--fixed-width {
    width: 145px;
    text-align: center;
}

/**
 * Buttons with icons
 */

.btn--hasIcon span + svg {
    margin-left: calc($unit * 2);
}

.btn--hasIcon svg + span {
    margin-left: calc($unit * 2);
}

/**
 * Special buttons
 */

.btn--close {
    width: calc($unit * 7);
    height: calc($unit * 7);
    border-radius: $global-rounded;
}
