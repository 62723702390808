/*------------------------------------*\
    # utils.opacity
\*------------------------------------*/

.o {
}

.o-100 {
  opacity: 1;
}

.o-80 {
  opacity: 0.8;
}

.o-60 {
  opacity: 0.6;
}

.o-40 {
  opacity: 0.4;
}

.o-20 {
  opacity: 0.2;
}

.o-zero {
  opacity: 0;
}

.o-zero {
    opacity: 0;
}
