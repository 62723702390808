/*------------------------------------*\
    # block.page
\*------------------------------------*/

.page {
    max-width: 900px;
    padding: 0 calc($unit * 15);
    margin: 0 auto;
}

.page__header {
    padding-top: calc($unit * 10);
    margin-bottom: calc($unit * 15);
}

.page__content {
    padding-bottom: calc($unit * 10);
}
