/*------------------------------------*\
    # component.alert
\*------------------------------------*/

.alert {
    position: relative;
    display: block;
    @mixin type-scale $type-tiny, 4;
    padding: calc($unit * 3);
    border-radius: $global-radial;

    &:empty {
        display: none;
    }

    & + .alert {
        margin-top: calc($unit * 1);
    }
}

.alert--success {
    background: color($color-success a(0.1));
}

.alert--warning {
    background: color($color-warning a(0.1));
}

.alert--note {
    background: color($color-note a(0.1));
}
