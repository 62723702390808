/*------------------------------------*\
    # utils.position
\*------------------------------------*/

.u-pos {
}

.u-pos--rel {
  position: relative;
}

.u-pos--abs {
  position: absolute;
}

/* z-index positioning */

.u-z-0 {
  z-index: 0;
}

.u-z-5 {
  z-index: 5;
}

/* z-index positioning */
.u-z-0 {
    z-index: 0;
}

.u-z-5 {
    z-index: 5;
}
