.map {
    height: 500px;
    width: 100%;
}

.map__input-suggestion {
    position: relative;
}

.map__suggestion {
    padding: 0 calc($unit * 4);
    margin-top: 60px;
    border-radius: $global-radial;
    position: absolute;
    left: 0;
    top: 0;

    width: 100%;

    cursor: pointer;
    white-space: nowrap;

    text-decoration: none;
    font-family: $ff-sans;

    background: white;
    border: 1px solid color($color-dark a(0.15));
    color: $color-dark;

    transition: all 0.2s ease-out;
    z-index: 3;
}
