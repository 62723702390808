/*------------------------------------*\
    # component.container
\*------------------------------------*/

.container {
    position: relative;
}

.container--empty {
    height: 100vh;
}
