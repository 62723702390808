/*------------------------------------*\
    # utils.width
\*------------------------------------*/

.u-width--full {
    width: 100%;
}

.u-width--half {
    width: 50%;
}

.u-width--20 {
    width: 20%;
}
