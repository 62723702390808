/*------------------------------------*\
    # component.loading
\*------------------------------------*/

@keyframes spinnerAnimation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.loading,
.loading:after {
    border-radius: 50%;
    width: 24px;
    height: 24px;
}

.loading {
    position: relative;
    margin: 60px auto;
    font-size: 10px;
    text-indent: -9999em;
    border-top: 2px solid $color-primary;
    border-right: 2px solid $color-primary;
    border-bottom: 2px solid transparent;
    border-left: 2px solid transparent;
    transform: translateZ(0);
    animation: spinnerAnimation 0.5s infinite linear;
}
