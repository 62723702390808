/*------------------------------------*\
    # utils.display
\*------------------------------------*/

.u-display {
}

.u-display--b {
    display: block;
}

.u-display--ib {
    display: inline-block;
}
