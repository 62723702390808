/*------------------------------------*\
    # utils.spacers
\*------------------------------------*/

.s- {
}

/* Top */
.s-top {
}

.s-top--tny {
    margin-top: calc($unit * 1);
}

.s-top--sml {
    margin-top: calc($unit * 2);
}

.s-top--med {
    margin-top: calc($unit * 4);
}

.s-top--lrg {
    margin-top: calc($unit * 8);
}

.s-top--xlrg {
    margin-top: calc($unit * 12);
}

/* Right */
.s-right {
}

.s-right--tny {
    margin-right: calc($unit * 1);
}

.s-right--sml {
    margin-right: calc($unit * 2);
}

.s-right--med {
    margin-right: calc($unit * 4);
}

.s-right--lrg {
    margin-right: calc($unit * 8);
}

.s-right--xlrg {
    margin-right: calc($unit * 12);
}

/* Bottom */
.s-bottom {
}

.s-bottom--tny {
    margin-bottom: calc($unit * 1);
}

.s-bottom--sml {
    margin-bottom: calc($unit * 2);
}

.s-bottom--med {
    margin-bottom: calc($unit * 4);
}

.s-bottom--lrg {
    margin-bottom: calc($unit * 8);
}

.s-bottom--xlrg {
    margin-bottom: calc($unit * 12);
}

/* Left */
.s-left {
}

.s-left--tny {
    margin-left: calc($unit * 1);
}

.s-left--sml {
    margin-left: calc($unit * 2);
}

.s-left--med {
    margin-left: calc($unit * 4);
}

.s-left--lrg {
    margin-left: calc($unit * 8);
}

.s-left--xlrg {
    margin-left: calc($unit * 12);
}
