/*------------------------------------*\
    # base.typography
\*------------------------------------*/

/**
 * Headings
 */

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    font-weight: $type-weight-regular;
    font-family: $ff-sans;
    text-rendering: optimizeLegibility;
}

h1 {
    @mixin type-scale $type-xxlarge, 12;
}

h2 {
    @mixin type-scale $type-xlarge, 10;
}

h3 {
    @mixin type-scale $type-large, 9;
}

h4,
h5,
h6 {
    @mixin type-scale $type-medium, 7;
}

/**
 * Paragraphs
 */

p {
    margin: 0;
}

/**
 * Lists (ol, ul, dd)
 */

ol,
ul,
dl {
    margin: 0;
    padding: 0;
    list-style: none;
}

/**
 * Anchors
 */

a {
    color: $color-primary;
    text-decoration: none;

    &:hover {
        color: color($color-primary blackness(+25%));
    }

    &:focus {
        color: color($color-primary blackness(+25%));
    }
}

/**
 * Typographic details
 */

hr {
    padding: calc($unit * 2) 0;
    border: 0;
    border-bottom: 1px solid color(white blackness(+15%));
}

em {
}

b,
strong {
    font-weight: $type-weight-bold;
}

/**
 * Tables
 */

table {
    th {
        text-align: left;
    }
}
