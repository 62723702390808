/*------------------------------------*\
    # utils.list
\*------------------------------------*/

.l-delimited {
    lost-flex-container: row;
}

.l-delimited dt {
    lost-column: 3/12;
    margin-bottom: calc($unit * 2);
    opacity: 0.6;
}

.l-delimited dd {
    lost-column: 9/12 2;
    margin-bottom: calc($unit * 2);
}

.l-boxed {
}

.l-boxed__item {
    padding: calc($unit * 4);
    background: white;
    border-radius: $global-radial;
    margin-bottom: calc($unit * 2);

    &:last-child {
        margin-bottom: 0;
    }
}

.l-bordered {
}

.l-bordered__item {
    padding: calc($unit * 4) 0;
    border-bottom: 1px solid color($color-dark a(0.1));

    &:last-child {
        border-bottom: 0;
    }
}
