/*------------------------------------*\
    # utils.bg
\*------------------------------------*/

.u-bg {
}

.u-bg--primary {
    background-color: $color-primary;
}
