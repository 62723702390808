/*------------------------------------*\
    # component.masterlayout
\*------------------------------------*/

.masterlayout {
    display: flex;
    min-height: 100vh;
}

.masterlayout__aside {
    flex: 1;
    background: $color-primary;
}

.masterlayout__map {
    flex: 4;
}
