/*------------------------------------*\
    # views.errorpage
\*------------------------------------*/

.v-errorpage {
    padding-top: calc($unit * 10);
}

.v-errorpage__content {
    
}
